import React from 'react';
import Headline from '../../headline/js/headline';
import Text from '../../text/js/text';

import classNames from 'classnames/bind';
const styles = require('../css/intro.scss');
const cx = classNames.bind(styles);

const Intro = () => (
	<article className={cx('m-intro')}>
		<Headline size={1}>
			Guess who's back!
			<br />
			The Muffin Tops Crew
		</Headline>
		<Text className={cx('m-intro__text')}>
			Ja Wahnsinn, diese Muffins. Von der Gosse direkt ins Rampenlicht und trotzdem auf dem Boden geblieben. Wir
			wissen, wo wir herkommen: Offenbach, Frankfurt, Bad Nauheim. Wir wissen, was wir wollen: dass du glücklich
			bist und das Tanzbein schwingst.
		</Text>
	</article>
);

export default Intro;
